const illustrationIcons = {
  IlluUsers:
    'M3.67 5.5c.33 0 .61.27.61.61s-.28.61-.61.61c-1.35 0-2.44 1.09-2.44 2.44s1.09 2.44 2.44 2.44c.34 0 .61.27.61.61s-.28.62-.61.62h-.14c-1.57.06-2.31 1.08-2.31 3.05v4.89h2.44c.34 0 .61.27.61.61S4 22 3.67 22H.61c-.34 0-.61-.27-.61-.61v-5.5c0-1.73.53-3.02 1.57-3.71A3.68 3.68 0 0 1 0 9.17C0 7.14 1.64 5.5 3.67 5.5zm14.66 0C20.36 5.5 22 7.14 22 9.17c0 1.25-.62 2.35-1.57 3.01 1.04.69 1.57 1.99 1.57 3.71v5.5c0 .34-.27.61-.61.61h-3.06c-.34 0-.61-.27-.61-.61s.27-.61.61-.61h2.44v-4.89c0-1.97-.74-3-2.31-3.05h-.14c-.34 0-.61-.27-.61-.61s.27-.61.61-.61c1.35 0 2.44-1.09 2.44-2.44s-1.09-2.44-2.44-2.44c-.34 0-.61-.27-.61-.61s.29-.63.62-.63zm-7.02-4.28c-2.2 0-3.98 1.78-3.98 3.97s1.78 3.97 3.97 3.97 3.97-1.78 3.97-3.97-1.77-3.97-3.96-3.97zM11 10.39c-2.36 0-4.28 1.92-4.28 4.28v6.11h8.56v-6.11c0-2.31-1.84-4.2-4.13-4.28H11zM11.31 0a5.188 5.188 0 0 1 2.32 9.83c1.71.93 2.87 2.75 2.87 4.83V22h-11v-7.33c0-2.21 1.3-4.11 3.18-4.99A5.18 5.18 0 0 1 6.11 5.2c0-2.87 2.33-5.2 5.2-5.2z',
  IlluOrganization:
    'M4.26 13.42c.34 0 .61.27.61.61 0 .34-.27.61-.61.61H3.04c-.34 0-.61-.27-.61-.61 0-.34.27-.61.61-.61h1.22zm4.26 0c.34 0 .61.27.61.61 0 .34-.27.61-.61.61H7.31c-.34 0-.61-.27-.61-.61 0-.34.27-.61.61-.61h1.21zm-4.26 2.44c.34 0 .61.27.61.61 0 .34-.27.61-.61.61H3.04c-.34 0-.61-.27-.61-.61 0-.34.27-.61.61-.61h1.22zm4.26 0c.34 0 .61.27.61.61 0 .34-.27.61-.61.61H7.31c-.34 0-.61-.27-.61-.61 0-.34.27-.61.61-.61h1.21zM4.26 18.3c.34 0 .61.27.61.61s-.27.61-.61.61H3.04c-.34 0-.61-.27-.61-.61s.27-.61.61-.61h1.22zm4.26 0c.34 0 .61.27.61.61s-.27.61-.61.61H7.31c-.34 0-.61-.27-.61-.61s.27-.61.61-.61h1.21zm1.83-6.09H1.22v8.52h9.13v-8.52zM9.13 1.53v9.46h1.83c.34 0 .61.27.61.61v9.15h9.21V6.06L9.13 1.53zM8.74.07l12.87 5.01c.24.09.39.32.39.57v15.71c0 .34-.27.61-.61.61H10.96c-.05 0-.11-.01-.16-.02H.61c-.34 0-.61-.27-.61-.61V11.6c0-.34.27-.61.61-.61h7.31V.64c-.01-.43.43-.72.82-.57z',
  IlluIdCard:
    'M18.33 6.72c.34 0 .61.27.61.61s-.27.61-.61.61h-6.72c-.34 0-.61-.27-.61-.61s.27-.61.61-.61h6.72zm0 3.67c.34 0 .61.27.61.61s-.27.61-.61.61h-6.72c-.34 0-.61-.27-.61-.61s.27-.61.61-.61h6.72zM7.63 7.94H4.6c-.18 0-.32.14-.32.32v5.48c0 .18.14.32.32.32h3.03c.18 0 .32-.14.32-.32V8.26c-.01-.17-.15-.32-.32-.32zm0-1.22c.85 0 1.54.69 1.54 1.54v5.48c0 .85-.69 1.54-1.54 1.54H4.6c-.85 0-1.54-.69-1.54-1.54V8.26c0-.85.69-1.54 1.54-1.54h3.03zm10.7 7.34c.34 0 .61.27.61.61 0 .34-.27.61-.61.61h-6.72c-.34 0-.61-.28-.61-.61 0-.34.27-.61.61-.61h6.72zm.08-9.17H3.59c-1.31 0-2.37 1.06-2.37 2.37v7.48c0 1.31 1.06 2.37 2.37 2.37H18.4c1.31 0 2.37-1.06 2.37-2.37V7.26a2.354 2.354 0 0 0-2.36-2.37zm0-1.22c1.98 0 3.59 1.61 3.59 3.59v7.48c0 1.98-1.61 3.59-3.59 3.59H3.59C1.61 18.33 0 16.72 0 14.74V7.26c0-1.98 1.61-3.59 3.59-3.59h14.82z',
  IlluUser:
    'M11.31 1.22c-2.2 0-3.98 1.78-3.98 3.97s1.78 3.97 3.97 3.97 3.97-1.78 3.97-3.97-1.77-3.97-3.96-3.97zM11 10.39c-2.36 0-4.28 1.92-4.28 4.28v6.11h8.56v-6.11c0-2.31-1.84-4.2-4.13-4.28H11zM11.31 0a5.188 5.188 0 0 1 2.32 9.83c1.71.93 2.87 2.75 2.87 4.83V22h-11v-7.33c0-2.21 1.3-4.11 3.18-4.99A5.18 5.18 0 0 1 6.11 5.2c0-2.87 2.33-5.2 5.2-5.2z'
}

export default illustrationIcons
