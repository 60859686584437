import { type CSSProperties, forwardRef, type SVGAttributes, useId } from 'react'

import { type IconName, iconPaths } from './icons'

interface IconProps extends SVGAttributes<SVGElement> {
  src: IconName
  width?: number
  height?: number
  viewBox?: string
  className?: string
  hasGradient?: boolean
  hoverGradientClassName?: string
  activeGradientClassName?: string
  style?: CSSProperties
}

export const defaultIconGradientHoverClassName = 'opacity-0 group-hover/iconHover:opacity-100 transition-opacity'
export const defaultIconGradientActiveClassName =
  'opacity-0 group-data-[active=true]/iconHover:opacity-100 transition-opacity'

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  (
    {
      src,
      width = 22,
      height = 22,
      viewBox = '0 0 22 22',
      hasGradient,
      activeGradientClassName = defaultIconGradientActiveClassName,
      hoverGradientClassName = defaultIconGradientHoverClassName,
      className,
      style,
      ...rest
    },
    ref
  ): JSX.Element => {
    const hoverGradientId = useId()
    const activeGradientId = useId()

    const defaultPathClassName =
      hasGradient === true
        ? 'group-hover/iconHover:opacity-0 group-data-[active=true]/iconHover:opacity-0 transition-opacity'
        : ''

    return (
      <svg
        className={className ?? ''}
        height={height}
        {...rest}
        ref={ref}
        style={style}
        viewBox={viewBox}
        width={width}
      >
        <path className={defaultPathClassName} d={iconPaths[src]} fill="currentColor" />

        {/*
        There are two additional paths included for hover/active styles.
        They can have gradients and/or a linear fill color assigned
       */}
        {hasGradient === true && (
          <>
            <defs>
              <linearGradient id={hoverGradientId}>
                <stop offset="0%" stopColor="#4480F0" />
                <stop offset="100%" stopColor="#4480F0" />
              </linearGradient>

              <linearGradient id={activeGradientId}>
                <stop offset="0%" stopColor="#00ADED" />
                <stop offset="100%" stopColor="#6468E7" />
              </linearGradient>
            </defs>

            <path className={hoverGradientClassName} d={iconPaths[src]} fill={`url(#${hoverGradientId})`} />
            <path className={activeGradientClassName} d={iconPaths[src]} fill={`url(#${activeGradientId})`} />
          </>
        )}
      </svg>
    )
  }
)

Icon.displayName = 'Icon'

/**
 * The IconGradient component is used to provide a global gradient for SVG icons.
 * See comments in the Icon component itself.
 */
// export const IconGradient = (): JSX.Element => {
//   return (
//     <svg className="pointer-events-none absolute h-0 w-0 overflow-hidden">
//       <defs>
//         <linearGradient x1="21.1158014%" y1="13.328503%" x2="74.2838542%" y2="80.2024148%" id="icon_gradient_1">
//           <stop stopColor="#00ADED" offset="0%" />
//           <stop stopColor="#6468E7" offset="100%" />
//         </linearGradient>
//       </defs>
//     </svg>
//   )
// }
