const Pothole =
  'M1.02 17.35h3.97c.3 1.49 1.58 2.6 3.11 2.6s2.81-1.12 3.11-2.6h9.78c.56 0 1.02.46 1.02 1.02s-.46 1.02-1.02 1.02h-8.45c-.9 1.56-2.55 2.6-4.43 2.6s-3.54-1.04-4.43-2.6H1.04C.46 19.4 0 18.94 0 18.37c0-.56.46-1.02 1.02-1.02zm9.87-15.31c-3.28 0-5.94 2.74-5.94 6.12s2.66 6.12 5.94 6.12c3.28 0 5.94-2.74 5.94-6.12s-2.66-6.12-5.94-6.12zm0-2.04c4.37 0 7.92 3.66 7.92 8.17s-3.55 8.17-7.92 8.17-7.92-3.66-7.92-8.17S6.52 0 10.89 0z'

const productIcons = {
  AirQuality:
    'm15.15 8.61-.11-.02v11.39h3.93c.53 0 .96-.39 1.02-.9l.01-.1v-4.55c0-2.89-2.09-5.3-4.85-5.82zM6.96 8.6l-.08.01C4.17 9.1 2.11 11.41 2 14.2v4.77c0 .55.46 1.01 1.03 1.01h3.93V8.6zM11 0c.51 0 .94.39.99.89l.01.12v7.6l1.04.69V7.52c0-.53.4-.96.91-1h.37c4.27.13 7.68 3.63 7.68 7.91v4.55c0 1.67-1.36 3.02-3.03 3.02h-4.93c-.55 0-1-.45-1-1.01V11.7L11 10.35 8.96 11.7v9.29c0 .52-.39.94-.88 1l-.12.01H3.03C1.36 22 0 20.65 0 18.98v-4.55c0-4.28 3.41-7.78 7.7-7.92h.24a.989.989 0 0 1 1.02 1v1.78L10 8.6V1.01C10 .45 10.45 0 11 0z',
  Energy:
    'M10.78 2.02v7.27h6.15l-6.15 10.69v-7.27H5.07l5.71-10.69zm-7.54 9.76-.07.14c-.58 1.31.4 2.81 1.9 2.81h3.64v5.25c0 2.08 2.83 2.8 3.88.99l6.15-10.69.07-.14c.63-1.32-.35-2.87-1.88-2.87h-4.08V2.02c0-2.11-2.91-2.8-3.91-.93l-5.7 10.69z',
  HazardWarnings:
    'M11 6c.55 0 1 .45 1 1v5c0 .55-.45 1-1 1s-1-.45-1-1V7c0-.55.45-1 1-1zm0 9c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM8.35 4.42l-.1.16-5.75 9.87c-.26.45-.4.97-.4 1.49 0 1.62 1.31 2.94 2.97 3.03l.19.01h11.49c.54 0 1.07-.13 1.54-.39 1.46-.79 2.02-2.54 1.3-3.97l-.09-.16-5.74-9.87c-.25-.43-.6-.79-1.02-1.06l-.19-.11c-1.47-.8-3.31-.34-4.2 1zm5.22-2.77c.84.46 1.54 1.13 2.01 1.94l5.74 9.87c1.42 2.44.52 5.52-2.01 6.89-.78.43-1.67.65-2.57.65H5.26C2.35 21 0 18.73 0 15.94c0-.87.23-1.72.67-2.48l5.74-9.87c1.42-2.44 4.63-3.31 7.16-1.94z',
  LaneMarking:
    'M21 11c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1zm-7.5 0c0-.55-.45-1-1-1h-3c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1zM6 11c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1h3c.55 0 1-.45 1-1zm15-6c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1zm0 12c0-.55-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1h18c.55 0 1-.45 1-1z',
  ParkingAnalytics:
    'M9.61 6.5v4.41h1.62c1.21 0 2.18-.98 2.18-2.18 0-1.15-.89-2.1-2.05-2.18L9.61 6.5zm0-2 1.62.05c2.31 0 4.18 1.87 4.18 4.18 0 2.24-1.76 4.07-3.98 4.18l-.21.01H9.6v4.48h-2V4.5h2.01zM19 3H3v16h16V3zm0-2c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2H3c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h16z',
  RoadSafety:
    'M20.98 18h-1.95L13.01 2.62l-.05-.11c-.18-.31-.53-.51-.93-.51H9.97l-.13.01c-.39.04-.72.27-.85.61L3.06 17.78l-.1.22H1.02l-.12.01c-.51.05-.9.48-.9.99 0 .55.46 1 1.02 1h19.96l.12-.01c.51-.05.9-.48.9-.99 0-.55-.46-1-1.02-1zm-6.12-5H7.14l1.19-3h5.35l1.18 3zm-4.15-9.01h.59L12.88 8H9.12l1.59-4.01zM6.35 15h9.31l1.19 3H5.16l1.19-3z',
  SlipperyRoad:
    'M20.16 2.4c.53.07.91.56.83 1.08s-.57.88-1.1.81l-.82-.11c.04.13.05.26.03.4l-.37 2.57c-.08.52-.57.88-1.1.81-.53-.07-.91-.56-.83-1.08l.37-2.57c.02-.14.07-.27.14-.38L5.52 2.31c.04.13.05.26.03.4l-.38 2.58c-.07.52-.57.88-1.1.81a.953.953 0 0 1-.83-1.08l.37-2.57c.02-.14.07-.27.14-.38L2.46 1.9c-.53-.08-.9-.56-.83-1.08s.57-.88 1.1-.81L20.16 2.4zm-6.94 7.28c.25-.47.83-.66 1.31-.42 3.21 1.6 4.6 2.95 4.22 4.48-.17.69-.53 1.14-1.3 1.63l-.31.19-.17.1-.38.2-.42.21-.47.23-.8.38-.37.18-.32.17-.27.15-.23.14-.1.06-.16.12-.12.1c-.09.08-.13.15-.15.21-.01.06.06.23.4.54.52.49 1.45 1.07 2.82 1.74.48.24.68.81.44 1.28s-.83.66-1.31.42c-3.17-1.55-4.56-2.86-4.26-4.36.19-.91.73-1.43 2.06-2.12l.42-.21.47-.23.6-.28.61-.3.34-.18.29-.16c.04-.03.09-.05.13-.07l.22-.14c.29-.19.43-.34.47-.46.02-.07-.05-.23-.38-.55-.51-.5-1.44-1.09-2.83-1.78-.51-.22-.7-.8-.45-1.27zM3.36 7.8c.25-.47.83-.66 1.31-.42.31.15.61.31.89.46l.54.3c2.7 1.53 3.83 2.88 3.46 4.35-.23.93-.79 1.49-2.16 2.23l-.42.22-.46.23-1.39.66-.4.2-.35.17-.31.17-.14.08-.25.15c-.46.29-.68.51-.72.7-.03.14.09.39.52.8.63.59 1.74 1.29 3.37 2.09.48.24.68.81.44 1.28-.24.47-.83.66-1.31.42l-.31-.15-.59-.3-.57-.31c-2.63-1.47-3.76-2.78-3.47-4.2.19-.93.74-1.51 2.04-2.23l.41-.22.22-.11.48-.24 1.15-.54.41-.2.37-.19.32-.17.28-.16.12-.07.22-.14.18-.13c.25-.19.37-.35.41-.5.04-.15-.07-.39-.49-.81-.62-.6-1.73-1.32-3.39-2.14a.955.955 0 0 1-.41-1.28z',
  SurfaceDamage: Pothole,
  SurfaceEvents:
    'M10.89 0c4.373 0 7.919 3.656 7.919 8.166s-3.546 8.167-7.92 8.167c-4.374 0-7.92-3.656-7.92-8.167C2.97 3.656 6.516 0 10.89 0Zm0 2.042c-3.281 0-5.94 2.742-5.94 6.124 0 3.383 2.659 6.125 5.94 6.125 3.28 0 5.939-2.742 5.939-6.125 0-3.382-2.66-6.124-5.94-6.124ZM1.02 17.354h3.967c.3 1.487 1.577 2.604 3.108 2.604 1.53 0 2.808-1.117 3.108-2.603l9.776-.001a1.02 1.02 0 0 1 0 2.041l-8.45.001C11.63 20.956 9.98 22 8.095 22c-1.886 0-3.536-1.045-4.434-2.604H1.02a1.02 1.02 0 0 1 0-2.042Z',
  Pothole,
  TrafficSigns:
    'M11.79 2.22c-.4-.38-1.06-.37-1.47.01L4.39 7.76c-.41.38-.42 1-.01 1.37l6.56 6.12 6.67-6.23c.38-.35.41-.91.09-1.29l-.08-.08-5.83-5.43zM8.86.87c1.23-1.15 3.2-1.16 4.41-.04l5.83 5.44c1.21 1.13 1.19 2.97-.04 4.12L12 16.98V21c0 .55-.45 1-1 1s-1-.45-1-1v-3.85l-7.11-6.63C1.73 9.44 1.7 7.69 2.8 6.54l.13-.14L8.86.87z',
  Waviness:
    'M10.97 19c-.46 0-.92-.12-1.34-.36l-2.21-1.26a.885.885 0 0 0-.89 0L4.5 18.55c-.96.55-2.11.47-3-.21l-1.05-.8c-.46-.35-.58-1.05-.28-1.57.3-.52.92-.66 1.38-.32l1.05.8c.3.23.68.25 1 .07l2.03-1.16c.84-.48 1.84-.48 2.68 0l2.21 1.25c.28.16.61.16.89 0l2.24-1.26a2.68 2.68 0 0 1 2.67.01l2.06 1.17c.32.18.7.16 1-.07l1.07-.8c.46-.35 1.08-.2 1.38.32.3.52.18 1.23-.28 1.57l-1.07.8c-.89.67-2.03.75-2.99.21l-2.06-1.17a.885.885 0 0 0-.89 0l-2.24 1.26c-.41.23-.87.35-1.33.35zm-.55-6.29-3.64-3.9c-.37-.39-.37-.9-.01-1.29s.84-.38 1.21.01l2.19 2.34-.03-6.96c0-.53.35-.91.84-.91.48 0 .85.39.85.91l.04 6.97 2.16-2.31c.36-.38.84-.38 1.21.01.36.39.37.91.01 1.29l-3.6 3.85c-.38.38-.87.38-1.23-.01z',
  Weather:
    'M19.84 7.39c.11-.45.16-.92.16-1.39 0-3.31-2.69-6-6-6a6 6 0 0 0-5.17 2.95c-.01.02-.02.04-.03.05a7.01 7.01 0 0 0-6.72 5.94A5.002 5.002 0 0 0 5 18h11c3.31 0 6-2.69 6-6 0-1.85-.84-3.51-2.16-4.61zM14 2c2.21 0 4 1.79 4 4 0 .11 0 .23-.02.34a5.882 5.882 0 0 0-3.16-.22 6.99 6.99 0 0 0-3.78-2.81C11.79 2.49 12.85 2 14 2zm2 14H5c-1.65 0-3-1.35-3-3 0-.96.47-1.87 1.25-2.44l.68-.49.13-.83A4.989 4.989 0 0 1 8.86 5l.73-.05.88.27c1.1.33 2.06 1.05 2.7 2.01l.75 1.11 1.31-.27c.68-.14 1.43-.09 2.1.15l.67.24.55.46A3.999 3.999 0 0 1 16 16zm-2 3c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm4 0c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1z'
}

export default productIcons
