const specialIcons = {
  ArrowHeading:
    'm11.71 5.29.02.02.91.91.19.19 1.37 1.37.68.68.61.61.22.22a.996.996 0 1 1-1.41 1.41l-.11-.11-.72-.72-.68-.68-.79-.78V16c0 .55-.45 1-1 1s-1-.45-1-1V8.41L8.83 9.59c-.07.06-.13.13-.19.18l-.89.89-.04.04A.996.996 0 1 1 6.3 9.29l.02-.02.91-.91c.05-.06.12-.12.18-.19l2.66-2.66.22-.22c.39-.39 1.03-.39 1.42 0z',
  Bike: 'M16.5 2c.83 0 1.5.67 1.5 1.5S17.33 5 16.5 5 15 4.33 15 3.5 15.67 2 16.5 2zm-3.61 1.55L14.62 7H18c.51 0 .94.39.99.88L19 8c0 .55-.45 1-1 1h-4c-.38 0-.73-.21-.89-.55l-1.53-3.06-.26.15C9.12 6.8 7.94 7.47 7.2 7.9l-.08.04.04.02.84.42c.29.15.63.32 1.03.51l.51.25c.08.05.17.1.26.14l.75.37c.62.31 1.33.67 2.16 1.08l.74.37c.56.28.73 1 .35 1.49l-3 4c-.33.44-.96.53-1.4.2-.44-.33-.53-.96-.2-1.4l2.28-3.04-.22-.11c-.1-.05-.2-.1-.29-.15l-.56-.28c-.27-.13-.52-.26-.76-.38l-.67-.34c-.14-.07-.28-.14-.41-.2L6.77 10c-.36-.18-.64-.32-.87-.43l-.27-.14c-.08-.04-.16-.08-.23-.11l-.12-.06c-.16-.08-.3-.15-.47-.23l-.26-.14c-.71-.35-.74-1.36-.05-1.76.77-.43 1.03-.59 2.76-1.57l.3-.17c.9-.51 2.14-1.22 3.94-2.25.5-.29 1.14-.1 1.39.41zM18 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM4 14c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm14-2c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zM4 12c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4z',
  BrokenDownVehicle:
    'm8.21 6.79 2.83 2.83 2.83-2.83a.996.996 0 1 1 1.41 1.41l-2.83 2.83 2.83 2.83c.36.36.39.93.08 1.32l-.08.09a.996.996 0 0 1-1.41 0l-2.83-2.83-2.83 2.83a.996.996 0 1 1-1.41-1.41l2.83-2.83-2.84-2.82a.995.995 0 0 1-.08-1.32l.08-.09c.39-.4 1.03-.4 1.42-.01zM11 2a9 9 0 1 0 .001 18.001A9 9 0 0 0 11 2zm0-2c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0z',
  Bump: 'M10.974 19c-.46 0-.919-.118-1.338-.356l-2.208-1.256a.891.891 0 0 0-.894.003L4.5 18.553c-.959.551-2.112.47-3.003-.206l-1.054-.798c-.458-.347-.583-1.052-.276-1.573.304-.521.922-.663 1.383-.315l1.052.797a.896.896 0 0 0 1.001.07l2.033-1.164a2.683 2.683 0 0 1 2.683-.004l2.208 1.254c.276.157.61.159.888.002l2.242-1.264a2.686 2.686 0 0 1 2.67.006l2.06 1.174c.318.182.7.156.996-.068l1.068-.804c.46-.347 1.078-.204 1.383.319.303.521.177 1.226-.283 1.572l-1.067.804c-.89.67-2.034.75-2.991.205l-2.06-1.174a.893.893 0 0 0-.89-.002l-2.241 1.264a2.711 2.711 0 0 1-1.33.352ZM11 2a6 6 0 1 1 0 12 6 6 0 0 1 0-12Zm0 2a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z',

  Car: 'M15.5 4H6L3.29 9h15.37L15.5 4zm3.5 7H3v1h1c.55 0 1 .45 1 1s-.45 1-1 1H3v2h16v-2h-1c-.55 0-1-.45-1-1s.45-1 1-1h1v-1zm-3.4-9c.63 0 1.22.36 1.55.96l3.58 5.52c.18.31.27.68.27 1.05V20h-3v-2H4v2H1V9.53c0-.33.07-.65.21-.94l3.07-5.52C4.59 2.41 5.21 2 5.89 2h9.71z',
  CarSide:
    'M43.802 9.11a.87.87 0 0 0-.576-.819L31.875 4.844 25.508.977a.863.863 0 0 0-.216-.094c-7.695-2.163-19.105.315-19.587.422a.867.867 0 0 0-.403.211L1.586 4.957A3.932 3.932 0 0 0 .328 7.836v7.751c0 .481.389.869.869.869h2.039C3.938 18.51 5.865 20 8.154 20s4.215-1.49 4.917-3.543h15.381C29.153 18.51 31.08 20 33.368 20c2.288 0 4.215-1.49 4.917-3.543h5.517c.48 0 .869-.389.869-.869l-.869-6.478zM8.154 18.261a3.482 3.482 0 0 1-3.478-3.478c0-1.918 1.56-3.478 3.478-3.478s3.478 1.56 3.478 3.478a3.482 3.482 0 0 1-3.478 3.478zm25.214 0a3.482 3.482 0 0 1-3.478-3.478c0-1.918 1.56-3.478 3.478-3.478s3.478 1.56 3.478 3.478a3.481 3.481 0 0 1-3.478 3.478zm5.211-3.543c-.036-2.846-2.356-5.151-5.21-5.151s-5.174 2.305-5.21 5.151H13.364c-.036-2.846-2.356-5.151-5.21-5.151s-5.174 2.305-5.21 5.151h-.877V7.836c0-.606.255-1.19.7-1.603l3.54-3.276c1.633-.336 11.698-2.264 18.401-.43l6.34 3.849c.05.03.103.057.157.076l10.858 3.272.87 4.995h-4.354z',
  CrossWind:
    'M1 9c-.55 0-1-.45-1-1 0-.51.39-.94.88-.99L1 7h11c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 .55-.45 1-1 1s-1-.45-1-1c0-2.21 1.79-4 4-4s4 1.79 4 4-1.79 4-4 4H1zm10 4c.55 0 1 .45 1 1 0 .51-.39.94-.88.99L11 15H1c-.55 0-1-.45-1-1 0-.51.39-.94.88-.99L1 13h10zm7 0c2.21 0 4 1.79 4 4s-1.79 4-4 4H1c-.55 0-1-.45-1-1 0-.51.39-.94.88-.99L1 19h17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 .55-.45 1-1 1s-1-.45-1-1c0-2.21 1.79-4 4-4z',
  Data: 'M16.87 15.01c1.13 0 2.05.89 2.05 1.99s-.92 1.99-2.05 1.99H5.13C4 18.99 3.08 18.1 3.08 17s.92-1.99 2.05-1.99h11.74zm0-2.01H5.13C2.85 13 1 14.79 1 17s1.85 4 4.13 4h11.75c2.28 0 4.13-1.79 4.13-4s-1.86-4-4.14-4zm0-9.99c1.13 0 2.05.89 2.05 1.99S18 6.99 16.87 6.99H5.13C3.99 6.99 3.07 6.1 3.07 5s.92-1.99 2.05-1.99h11.75zm0-2.01H5.13C2.85 1 1 2.79 1 5s1.85 4 4.13 4h11.75C19.15 9 21 7.21 21 5s-1.85-4-4.13-4z',
  Pedestrian:
    'M11.5 5.01c.83 0 1.5-.67 1.5-1.51a1.5 1.5 0 1 0-3 .01c0 .83.67 1.5 1.5 1.5zm.81 2.07 1.63 1.95h3.05c.52 0 .94.39 1 .89l.01.12c0 .55-.45 1-1.01 1H13l-1.25-1.5-.59 2.18 3.33 2.71.37.3V19c0 .55-.45 1-1.01 1s-1.01-.45-1.01-1v-3.31l-3.28-2.67-.66 2.36-.05.18-.11.15-2.92 3.89c-.33.44-.96.54-1.41.2a.996.996 0 0 1-.2-1.41l2.81-3.74 1.3-4.63-2.73 1.85c-.46.31-1.09.19-1.4-.27s-.19-1.08.27-1.4l5.17-3.5c.85-.58 2.01-.41 2.68.38z',
  Map: 'M13.923.088a1 1 0 0 1 .752-.028l3.333 1.21 3.333 1.211a1 1 0 0 1 .659.94V20a1 1 0 0 1-1.41.912l-.512-.23-.107-.049-.11-.05c-.692-.31-1.665-.748-5.321-2.394l-.141-.063-.146.088-.28.169-1.47.882-2.246 1.347-.5.3-.979.587-.103.062-.051.031-.104.062c-.106.064-.215.13-.339.203a1 1 0 0 1-.826.093l-.475-.155-.111-.037-.357-.117-.136-.044-5.587-1.83A1 1 0 0 1 0 18.815V2.236a1 1 0 0 1 1.256-.966l.475.126.168.044 5.383 1.424.298.078.05-.022 2.71-1.22.25-.112L13.288.374l.13-.059.505-.227ZM2 3.536v14.555l.253.083 4.156 1.362.091.029V4.725l-.03-.007-4.42-1.169L2 3.536Zm11.5-1.065-.034.016-2.056.925-2.698 1.214-.13.059-.065.029-.017.007v14.612l.06-.035.168-.101.5-.3 2.245-1.347.94-.564.811-.487.276-.166V2.471Zm2 .016v13.941l.151.068 4.276 1.924.073.033V4.122l-.043-.016-2.382-.865-.5-.181-1.455-.529-.12-.044Z',
  HeavyRain:
    'M10.76 2.4c1.21.47 2.2 1.31 2.78 2.38l.34.63.74-.1c.19-.03.37-.04.56-.04.99 0 1.94.36 2.64 1 .69.63 1.07 1.48 1.07 2.37 0 .88-.38 1.73-1.07 2.37-.7.64-1.65 1-2.64 1H8.63c-1.32 0-2.6-.43-3.6-1.21-.99-.78-1.65-1.85-1.85-3.02-.21-1.18.05-2.38.73-3.39.69-1.02 1.76-1.78 3.01-2.15 1.26-.37 2.62-.31 3.84.16zM6.3.33c-1.73.5-3.2 1.56-4.16 2.98A6.595 6.595 0 0 0 1.09 8.1c.29 1.66 1.21 3.16 2.59 4.24S6.81 14.01 8.62 14h6.55c1.54 0 3.01-.56 4.1-1.56 1.1-1.01 1.72-2.38 1.72-3.81 0-1.43-.62-2.8-1.72-3.81l-.21-.18a6.137 6.137 0 0 0-3.89-1.38h-.14l-.11-.16c-.83-1.13-2-2.02-3.37-2.56-1.56-.6-3.3-.71-4.93-.3L6.3.33zM17 15c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm0 4c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm-4-4c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm0 4c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm-8-4c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm4 0c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm-4 4c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm4 0c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1z',
  HazardLight:
    'm10.89 11.06-.02.04c-.38.68-.96 1.74-2.04 3.68L8.7 15h4.38l-.02-.04c-.38-.68-.97-1.74-2.09-3.76l-.08-.14zm.87-2.55c2.36 4.24 3 5.4 3.42 6.16l.06.11.06.11c.11.2.21.38.34.62.37.67-.11 1.49-.87 1.49H7c-.76 0-1.24-.82-.87-1.49 2.42-4.35 3.03-5.46 3.46-6.22l.09-.16.15-.27.06-.11c.04-.08.09-.15.13-.24a.997.997 0 0 1 1.74 0zM11 3 2 19h18L11 3zm1.74-.98 9 16C22.49 19.35 21.53 21 20 21H2C.47 21-.49 19.35.26 18.02l9-16c.76-1.36 2.72-1.36 3.48 0z',
  Fog: 'M2.53 3.6c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.12-.07.93-.7c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.11-.07.93-.7c1.01-.76 2.38-.8 3.43-.12l.17.12 2.13 1.6c.44.33.53.96.2 1.4a.99.99 0 0 1-1.29.27l-.1-.07-2.13-1.6c-.32-.24-.75-.26-1.09-.07l-.11.07-.93.7c-1.01.76-2.38.8-3.43.12l-.18-.12-.93-.7c-.32-.24-.75-.26-1.09-.07l-.11.07-.93.7c-1.01.76-2.38.8-3.43.12l-.17-.12-.94-.7c-.32-.24-.74-.26-1.09-.07l-.11.07L1.6 6.8c-.44.33-1.07.24-1.4-.2-.31-.41-.25-.97.1-1.32l.1-.08 2.13-1.6zm0 6c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.11-.07.94-.7c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.11-.07.93-.7c1.01-.76 2.38-.8 3.43-.12l.17.12 2.13 1.6c.44.33.53.96.2 1.4a.99.99 0 0 1-1.29.27l-.1-.07-2.13-1.6c-.32-.24-.75-.26-1.09-.07l-.11.07-.93.7c-1.01.76-2.38.8-3.43.12l-.17-.12-.93-.7c-.32-.24-.75-.26-1.09-.07l-.12.07-.93.7c-1.01.76-2.38.8-3.43.12l-.17-.12-.93-.7c-.32-.24-.75-.26-1.09-.07l-.12.07-2.13 1.6c-.44.33-1.07.24-1.4-.2-.31-.41-.25-.97.1-1.32l.1-.08 2.13-1.6zm0 6c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.11-.07.93-.7c1.01-.76 2.38-.8 3.43-.12l.17.12.93.7c.32.24.75.26 1.09.07l.11-.07.93-.7c1.01-.76 2.38-.8 3.43-.12l.17.12 2.13 1.6c.44.33.53.96.2 1.4a.99.99 0 0 1-1.29.27l-.09-.07-2.13-1.6c-.32-.24-.75-.26-1.09-.07l-.11.07-.93.7c-1.01.76-2.38.8-3.43.12l-.17-.12-.93-.7c-.32-.24-.75-.26-1.09-.07l-.12.07-.93.7c-1.01.76-2.38.8-3.43.12l-.17-.12-.93-.7c-.32-.24-.75-.26-1.09-.07l-.12.07-2.13 1.6c-.44.33-1.07.24-1.4-.2-.31-.41-.25-.97.1-1.32l.1-.08 2.13-1.6z',
  EmergencyBrake:
    'M11 6.99c.55 0 1 .45 1 1v3.37c0 .55-.45 1-1 1s-1-.45-1-1V7.99c0-.55.45-1 1-1zm0 5.99c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0-7.72c-3.18 0-5.75 2.57-5.75 5.74 0 3.17 2.57 5.74 5.75 5.74s5.75-2.57 5.75-5.74c0-3.17-2.57-5.74-5.75-5.74zm0-2c4.28 0 7.75 3.46 7.75 7.73 0 4.27-3.47 7.73-7.75 7.73S3.25 15.27 3.25 11 6.72 3.26 11 3.26zM5.82 2.4c.33.44.24 1.07-.2 1.4A8.956 8.956 0 0 0 2 11c0 2.86 1.35 5.5 3.6 7.18.44.33.53.96.2 1.4-.33.44-.96.53-1.4.2C1.65 17.72 0 14.49 0 11c0-3.51 1.66-6.74 4.42-8.8.45-.33 1.07-.24 1.4.2zm11.78-.18A10.94 10.94 0 0 1 22 11c0 3.51-1.66 6.74-4.43 8.8-.44.33-1.07.24-1.4-.2-.33-.44-.24-1.07.2-1.4A8.937 8.937 0 0 0 20 11c0-2.86-1.35-5.5-3.6-7.19-.44-.33-.53-.96-.2-1.4.33-.43.96-.52 1.4-.19z',
  Rain: 'M18 19c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm-4 0c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zm-4 0c.55 0 1 .45 1 1v1c0 .55-.45 1-1 1s-1-.45-1-1v-1c0-.55.45-1 1-1zM9.2 2c2.69 0 5.04 1.34 6.28 3.31C18.04 5.69 20 7.7 20 10.12c0 2.62-2.3 4.76-5.17 4.87h-5.9C5.08 14.87 2 12.01 2 8.5 2 4.91 5.22 2 9.2 2zm.01-2C4.15 0 0 3.78 0 8.5c0 4.6 3.95 8.33 8.87 8.5h5.99c3.98-.15 7.15-3.15 7.15-6.86V9.9c-.11-2.9-2.15-5.35-5-6.26l-.31-.09-.16-.2C14.81 1.26 12.12 0 9.21 0z',
  Lock: 'M11 3C9 3 7.29 4.24 6.6 6h8.8C14.71 4.24 13 3 11 3zm0 9c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1s-1-.45-1-1v-3c0-.55.45-1 1-1zm8-4H3v11h16V8zm-8-7a6.72 6.72 0 0 1 6.5 5H19c1.1 0 2 .87 2 1.95v11.11c0 1.07-.9 1.95-2 1.95H3c-1.1 0-2-.87-2-1.95V7.95C1 6.87 1.9 6 3 6h1.5c.76-2.88 3.38-5 6.5-5z',
  Statistics:
    'M11 3C9 3 7.29 4.24 6.6 6h8.8C14.71 4.24 13 3 11 3zm0 9c.55 0 1 .45 1 1v3c0 .55-.45 1-1 1s-1-.45-1-1v-3c0-.55.45-1 1-1zm8-4H3v11h16V8zm-8-7a6.72 6.72 0 0 1 6.5 5H19c1.1 0 2 .87 2 1.95v11.11c0 1.07-.9 1.95-2 1.95H3c-1.1 0-2-.87-2-1.95V7.95C1 6.87 1.9 6 3 6h1.5c.76-2.88 3.38-5 6.5-5z',
  VehicleAccident:
    'M7.5 1c.55 0 1 .45 1 1v4.68c0 .55-.45 1-1 1s-1-.45-1-1V2c0-.55.45-1 1-1zM3.11 2.11 5.6 6.22c.25.41.15.94-.22 1.24l-.13.08-.03.02c-.47.26-1.06.1-1.33-.36L1.4 3.09a.944.944 0 0 1 .32-1.3l.06-.03a.975.975 0 0 1 1.33.35zm10-.41.14.07c.46.25.63.83.37 1.29L11.11 7.2c-.26.42-.78.59-1.23.41l-.13-.07a.932.932 0 0 1-.37-1.28l2.51-4.15c.25-.43.78-.59 1.22-.41zm-.05 8.84H5.38L3 15.31v1.91h13v-1.91l-2.94-4.77zm3-4.77c.7 0 1.34.37 1.71.97l2.94 4.64c.19.32.29.69.29 1.06v4.69c0 .55-.45 1-1 1s-1-.45-1-1v-.88h-1V20c0 .55-.45 1-1 1s-1-.45-1-1v-.88H3V20c0 .55-.45 1-1 1s-1-.45-1-1v-4.7c0-.33.08-.65.23-.95L3.6 9.72c.35-.67 1.03-1.09 1.77-1.09h7.69c.7 0 1.34.37 1.71.97l2.94 4.64.06.11h1.24v-1.91l-2.94-4.77h-2.61a.95.95 0 1 1 0-1.9h2.6z',

  RainIntensityNone:
    'M22 13.9c-.07-1.89-.97-3.58-2.38-4.79A6.005 6.005 0 0 0 14 1a6 6 0 0 0-5.17 2.95c-.01.02-.02.05-.03.07C3.93 4.22 0 7.91 0 12.5c0 4.6 3.95 8.33 8.87 8.5h5.99c3.98-.15 7.15-3.15 7.15-6.86L22 13.9zM14 3c2.21 0 4 1.79 4 4 0 .34-.06.66-.14.98-.28-.13-.56-.25-.86-.34l-.31-.09-.16-.2c-1.33-1.61-3.24-2.72-5.38-3.15C11.89 3.45 12.9 3 14 3zm.83 16h-5.9C5.08 18.87 2 16.01 2 12.5 2 8.91 5.22 6 9.2 6c2.69 0 5.04 1.34 6.28 3.31 2.56.38 4.52 2.39 4.52 4.81 0 2.63-2.3 4.77-5.17 4.88z',
  RainIntensityLow:
    'M9.207 0C4.148 0 0 3.779 0 8.5c0 4.604 3.953 8.332 8.867 8.499l5.72.001.266-.005c3.98-.15 7.147-3.15 7.147-6.862l-.004-.236c-.106-2.898-2.152-5.354-4.999-6.26l-.306-.09-.16-.2C14.81 1.263 12.121 0 9.206 0ZM9.2 2c2.694 0 5.042 1.335 6.277 3.313C18.042 5.692 20 7.702 20 10.125c0 2.623-2.295 4.763-5.172 4.87L14.6 15H8.93C5.082 14.87 2 12.01 2 8.5 2 4.91 5.224 2 9.2 2ZM14 19a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Z',
  RainIntensityMedium:
    'M9.207 0C4.148 0 0 3.779 0 8.5c0 4.604 3.953 8.332 8.867 8.499l5.72.001.266-.005c3.98-.15 7.147-3.15 7.147-6.862l-.004-.236c-.106-2.898-2.152-5.354-4.999-6.26l-.306-.09-.16-.2C14.81 1.263 12.121 0 9.206 0ZM9.2 2c2.694 0 5.042 1.335 6.277 3.313C18.042 5.692 20 7.702 20 10.125c0 2.623-2.295 4.763-5.172 4.87L14.6 15H8.93C5.082 14.87 2 12.01 2 8.5 2 4.91 5.224 2 9.2 2Zm.8 17a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm-4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm8 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Z',
  RainIntensityHigh:
    'M9 19a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm-4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm4-4a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm-4 0a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm8 4a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm0-4a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm4 4a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1Zm0-4a1 1 0 0 1 1 1v1a1 1 0 0 1-2 0v-1a1 1 0 0 1 1-1ZM6.3.33C4.574.834 3.099 1.891 2.142 3.311 1.181 4.738.808 6.436 1.094 8.095c.285 1.657 1.206 3.162 2.594 4.245C5.068 13.417 6.82 14.005 8.63 14h6.55c1.536 0 3.01-.56 4.102-1.558C20.38 11.436 21 10.067 21 8.636c0-1.43-.62-2.8-1.718-3.805l-.21-.182c-1.066-.885-2.452-1.376-3.891-1.376l-.136.002-.12-.173C14.1 1.969 12.929 1.078 11.55.546A8.226 8.226 0 0 0 6.625.242l-.324.087Zm4.458 2.068c1.211.468 2.196 1.315 2.779 2.383l.345.633.742-.103a4.06 4.06 0 0 1 .557-.038c.993 0 1.943.36 2.64.998.689.63 1.074 1.481 1.074 2.365 0 .885-.385 1.735-1.074 2.366-.697.638-1.647.998-2.64.998H8.627c-1.325.004-2.602-.425-3.603-1.206-.995-.776-1.65-1.848-1.853-3.022a4.625 4.625 0 0 1 .743-3.382c.687-1.019 1.753-1.782 3.007-2.15a6.017 6.017 0 0 1 3.837.158Z',

  BusStop:
    'M15 16h-2v-4H9v4H7V6h2v4h4V6h2v10zM11 2c-4.971 0-9 4.029-9 9s4.029 9 9 9 9-4.029 9-9-4.029-9-9-9zm0-2c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0z',
  Medical:
    'M12 4v2h2v2h-2v2h-2V8H8V6h2V4h2zm0 13h-2v3h2v-3zm4-15H6v18h2v-5h6v5h2V2zm4 10h-2v8h2v-8zM4 12H2v8h2v-8zM17.5 0a.5.5 0 0 1 .5.5V10h3.5a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5H.5a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H4V.5a.5.5 0 0 1 .5-.5h13z',
  Public:
    'M11 16a5 5 0 0 1 5 5 1 1 0 0 1-1.993.117L14 21a3 3 0 1 0-6 0 1 1 0 0 1-2 0 5 5 0 0 1 5-5zm6-14a2 2 0 1 0-.001 3.999A2 2 0 0 0 17 2zM5 2a2 2 0 1 0-.001 3.999A2 2 0 0 0 5 2zm12-2a4 4 0 1 1 0 8 4 4 0 0 1 0-8zM5 0a4 4 0 1 1 0 8 4 4 0 0 1 0-8zm12 11c-.784 0-1.497.3-2.031.792A3.932 3.932 0 0 1 14.622 13h5.207l-.004-.012a3.002 3.002 0 0 0-2.649-1.983L17 11zM5 11a3 3 0 0 0-2.758 1.817L2.17 13l5.208.001a3.98 3.98 0 0 1-.348-1.205 2.968 2.968 0 0 0-1.854-.791L5 11zm6-1.7a2 2 0 1 0-.001 3.999A2 2 0 0 0 11 9.3zm0-2a4.002 4.002 0 0 1 3.697 2.469C15.311 9.288 16.065 9 17 9a5 5 0 0 1 5 5v1l-9.48.001c-.468.193-.982.299-1.52.299s-1.052-.106-1.52-.299L0 15v-1a5 5 0 0 1 5-5c.956 0 1.676.346 2.257.892A3.997 3.997 0 0 1 11 7.3z',
  Restaurants:
    'M9 0a1 1 0 0 1 1 1v6c0 .51-.381.931-.875.992L9 8l-3-.001V12H4l-.001-4.001L1 8a1 1 0 0 1-1-1V1a1 1 0 0 1 2 0v5h2V1a1 1 0 0 1 2 0v5h2V1a1 1 0 0 1 1-1zm8.5 2H15v10h5V4.5a2.501 2.501 0 0 0-2.336-2.495L17.5 2zm0-2A4.5 4.5 0 0 1 22 4.5V13a1 1 0 0 1-1 1l-6.001-.001L15 21a1 1 0 0 1-2 0V1a1 1 0 0 1 1-1h3.5zM6 14H4v6h2v-6zm1-2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4z',
  School:
    'M15 4a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h8zm0 4a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h8zm0 4a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h8zm3-10H4v18h14V2zm1-2a1 1 0 0 1 1 1v20a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h16z',
  Shopping:
    'M19.991 5.868A1 1 0 0 0 19 5h-2V1a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v4H3a1 1 0 0 0-.991.868l-2 15A1 1 0 0 0 1 22h20a1 1 0 0 0 .991-1.132l-2-15zM7 2h8v3H7V2zM2.142 20 3.875 7h14.249l1.733 13H2.142z'
}

export default specialIcons
