const GeneralWarning =
  'M11 2a9 9 0 1 0 .001 18.001A9 9 0 0 0 11 2zm0-2c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0zm0 16c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm0-3.99c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1z'

const uiIcons = {
  ArrowBottom:
    'M11.71 16.71a.996.996 0 0 1-1.41 0l-10-10A.996.996 0 1 1 1.71 5.3L11 14.59l9.29-9.29c.36-.36.93-.39 1.32-.08l.09.08c.39.39.39 1.02 0 1.41l-9.99 10z',
  ArrowTop:
    'M10.29 5.29a.996.996 0 0 1 1.41 0l10 10a.996.996 0 1 1-1.41 1.41L11 7.41 1.71 16.7c-.36.36-.93.39-1.32.08L.3 16.7a.996.996 0 0 1 0-1.41l9.99-10z',
  ArrowLeft:
    'M5.29 11.71a.996.996 0 0 1 0-1.41l10-10a.996.996 0 1 1 1.41 1.41L7.41 11l9.29 9.29c.36.36.39.93.08 1.32l-.08.09a.996.996 0 0 1-1.41 0l-10-9.99z',
  ArrowRight:
    'M16.71 10.29c.39.39.39 1.02 0 1.41l-10 10a.996.996 0 1 1-1.41-1.41L14.59 11l-9.3-9.29A.995.995 0 0 1 5.21.39L5.29.3A.996.996 0 0 1 6.7.3l10.01 9.99z',
  ArrowSmallBottom:
    'M10.29 14.71c.39.39 1.02.39 1.41 0l6-6a.996.996 0 1 0-1.41-1.41L11 12.59l-5.29-5.3a.995.995 0 0 0-1.32-.08l-.1.08a.996.996 0 0 0 0 1.41l6 6.01z',
  ArrowSmallTop:
    'M10.29 7.29a.996.996 0 0 1 1.41 0l6 6a.996.996 0 1 1-1.41 1.41L11 9.41 5.71 14.7c-.36.36-.93.39-1.32.08l-.09-.08a.996.996 0 0 1 0-1.41l5.99-6z',
  ArrowSmallLeft:
    'M7.29 11.71a.996.996 0 0 1 0-1.41l6-6a.996.996 0 1 1 1.41 1.41L9.41 11l5.29 5.29c.36.36.39.93.08 1.32l-.08.09a.996.996 0 0 1-1.41 0l-6-5.99z',
  ArrowSmallRight:
    'M14.71 11.71a.996.996 0 0 0 0-1.41l-6-6A.996.996 0 1 0 7.3 5.71L12.59 11 7.3 16.29c-.36.36-.39.93-.08 1.32l.08.09c.39.39 1.02.39 1.41 0l6-5.99z',
  ArrowSpecialDown:
    'M11 4c.55 0 1 .45 1 1v9.58l4.29-4.29c.36-.36.93-.39 1.32-.08l.09.08c.39.39.39 1.02 0 1.41l-6 6a.996.996 0 0 1-1.41 0l-6-6a.996.996 0 1 1 1.41-1.41l4.3 4.29V5c0-.55.45-1 1-1z',
  Check:
    'M5.09 12.5a.996.996 0 0 1 0-1.41c.36-.36.92-.39 1.32-.09l.09.08 3.29 3.29 7.29-8.29c.37-.35.93-.38 1.33-.08l.09.08c.36.36.39.93.08 1.32l-8.79 9.8-4.7-4.7z',
  Backspace:
    'M8.96 7.46a.996.996 0 0 1 1.41 0l2.12 2.12 2.12-2.12a.996.996 0 0 1 1.41 0c.36.36.39.93.08 1.33l-.08.09L13.91 11l2.12 2.12.08.09c.31.39.28.96-.08 1.33a.996.996 0 0 1-1.41 0l-2.12-2.12-2.12 2.12a.996.996 0 0 1-1.41 0c-.36-.36-.39-.93-.08-1.33l.08-.09L11.09 11 8.96 8.88l-.07-.09c-.31-.39-.29-.96.07-1.33zM20 4H7.5l-5.25 7 5.25 7H20V4zm1-2c.55 0 1 .45 1 1v16c0 .55-.45 1-1 1H7c-.31 0-.61-.15-.8-.4l-6-8a.99.99 0 0 1 0-1.2l6-8c.19-.25.49-.4.8-.4h14z',
  Close:
    'm4.28 2.88.09.08L11 9.58l6.63-6.63.09-.08c.39-.31.96-.28 1.32.08.39.39.39 1.02 0 1.41L12.41 11l6.63 6.63c.39.39.39 1.02 0 1.41-.36.36-.93.39-1.32.08l-.09-.08L11 12.41l-6.63 6.63-.09.08c-.39.31-.96.28-1.32-.08a.996.996 0 0 1 0-1.41L9.58 11 2.96 4.37a.996.996 0 0 1 0-1.41c.36-.36.93-.39 1.32-.08z',
  Comment:
    'M18 4H4v14l5-4h9V4zm0-2c1.1 0 2 .9 2 2v10c0 1.1-.9 2-2 2H9.83l-4.41 3.41c-1.22 1.22-3.29.42-3.41-1.26L2 18V4c0-1.1.9-2 2-2h14z',
  Compare:
    'M8 11 2 6v10l6-5zm1.28 1.54-6 5C1.98 18.62 0 17.7 0 16V6c0-1.7 1.98-2.62 3.28-1.54l6 5c.96.8.96 2.28 0 3.08zM14 11l6 5V6l-6 5zm-1.28-1.54 6-5C20.02 3.38 22 4.3 22 6v10c0 1.7-1.98 2.62-3.28 1.54l-6-5c-.96-.8-.96-2.28 0-3.08z',
  Envelope:
    'M20 2H2C.9 2 0 2.9 0 4v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1.8 2L11 8.8 3.8 4h14.4zM2 18V5.2l8.45 5.63c.34.22.77.22 1.11 0L20 5.2V18H2z',
  Download:
    'M11.71 17.71a.996.996 0 0 1-1.41 0l-6-6a.996.996 0 1 1 1.41-1.41L11 15.59l5.29-5.29c.36-.36.93-.39 1.32-.08l.09.08c.39.39.39 1.02 0 1.41l-5.99 6zM3 6h16c1.33 0 1.33-2 0-2H3c-1.29 0-1.33 1.86-.13 1.99L3 6z',
  RSS: 'M5.17 8C10.05 8 14 11.96 14 16.83c0 .65-.52 1.17-1.17 1.17-.65 0-1.17-.52-1.17-1.17a6.49 6.49 0 0 0-6.49-6.49C4.52 10.34 4 9.82 4 9.17 4 8.52 4.52 8 5.17 8zm-.05-6C13.34 2 20 8.67 20 16.88c0 .62-.5 1.12-1.12 1.12-.62 0-1.12-.5-1.12-1.12 0-6.97-5.67-12.63-12.63-12.63C4.5 4.25 4 3.75 4 3.12S4.5 2 5.12 2zM3.98 16C5.1 16 6 16.9 6 17.98 6 19.1 5.09 20 3.98 20 2.9 20 2 19.09 2 17.98 2 16.9 2.88 16 3.98 16z',
  CircleCheck:
    'M15.64 7.23c.42.35.48.98.13 1.41l-5 6c-.38.45-1.06.48-1.48.07l-.09-.09-.39-.39-.26-.26-.22-.22-.05-.05-.22-.22-.09-.09-.59-.59-.08-.08a.996.996 0 1 1 1.41-1.41l.08.08.59.59.09.09.17.17.1.1.19.19 4.3-5.16c.36-.43.99-.49 1.41-.14zM11 2a9 9 0 1 0 .001 18.001A9 9 0 0 0 11 2zm0-2c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0z',
  FastForward:
    'M8 11 2 6v10l6-5zm1.28 1.54-6 5C1.98 18.62 0 17.7 0 16V6c0-1.7 1.98-2.62 3.28-1.54l6 5c.96.8.96 2.28 0 3.08zM20 11l-6-5v10l6-5zm1.28 1.54-6 5C13.98 18.62 12 17.7 12 16V6c0-1.7 1.98-2.62 3.28-1.54l6 5c.96.8.96 2.28 0 3.08z',
  Cars: 'M13.06 6.01H5.38l-2.81 5.01h13.78l-3.29-5.01zm3-3.01H8.38l-.57 1h5.25c.64 0 1.24.33 1.62.88l.09.14 3.94 5.87c.19.34.29.73.29 1.13v3h1V9.01L16.06 3zM17 13.02H2v5.01h15v-5.01zM2 21c0 .55-.45 1-1 1s-1-.45-1-1v-8.98c0-.35.08-.69.23-.99L3.6 5.15C3.95 4.45 4.63 4 5.38 4h.16L6.6 2.14c.32-.65.93-1.08 1.61-1.14h7.86c.7 0 1.34.39 1.71 1.02l3.94 5.87c.18.34.28.73.28 1.12V18c0 .55-.45 1-1 1s-1-.45-1-1v-.97h-1V21c0 .55-.45 1-1 1s-1-.45-1-1v-.97H2V21z',
  Pen: 'M17.27 4.73c-.82-.82-2.14-.82-2.96 0l-8.46 8.46 2.96 2.96 8.46-8.46c.77-.77.82-2 .12-2.83l-.12-.13zm-4.45-1.5a4.213 4.213 0 0 1 5.95 0 4.213 4.213 0 0 1 0 5.95l-8.46 8.46-6.92 2.31c-.55.18-1.15-.11-1.34-.67-.07-.22-.07-.45 0-.67l2.3-6.91 8.47-8.47z',
  Expand:
    'M20 3.5v6c0 .55-.45 1-1 1s-1-.45-1-1V5.91l-4.29 4.29a.996.996 0 1 1-1.41-1.41l4.29-4.29H13c-.51 0-.94-.39-.99-.88L12 3.5c0-.55.45-1 1-1h6c.55 0 1 .45 1 1zm-11 14H5.41l3.29-3.29a.996.996 0 1 0-1.41-1.41L4 16.09V12.5c0-.55-.45-1-1-1s-1 .45-1 1v6a1.019 1.019 0 0 0 1 1h6c.55 0 1-.45 1-1l-.01-.12A.996.996 0 0 0 9 17.5z',
  Eye: 'M11 10c-.55 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zm0-2c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0-4c-3.5 0-6.81 2.56-8.73 6.77l-.11.23.1.23c1.88 4.11 5.07 6.64 8.48 6.77H11c3.5 0 6.81-2.56 8.73-6.77l.1-.23-.1-.23C17.85 6.66 14.66 4.13 11.26 4H11zm0-2c4.78 0 8.93 3.65 11 9-2.07 5.35-6.22 9-11 9s-8.93-3.65-11-9c2.07-5.35 6.22-9 11-9z',
  EyeClosed:
    'M11 4c-3.5 0-6.81 2.56-8.73 6.77l-.11.23.1.23c.77 1.69 1.77 3.11 2.91 4.21l3.13-3.13c-.19-.39-.3-.84-.3-1.31 0-1.66 1.34-3 3-3 .47 0 .92.11 1.32.3l2.97-2.97c-1.26-.81-2.63-1.28-4.03-1.33H11zm5.82 2.56L13.7 9.68c.19.4.3.84.3 1.32 0 1.66-1.34 3-3 3-.47 0-.92-.11-1.32-.3l-2.97 2.97c1.26.81 2.63 1.28 4.03 1.33H11c3.5 0 6.81-2.56 8.73-6.77l.1-.23-.1-.23c-.71-1.55-1.6-2.88-2.63-3.93l-.28-.28zm3.89-5.27c.38.38.38 1 0 1.38l-2.48 2.48C19.81 6.67 21.1 8.67 22 11c-2.07 5.35-6.22 9-11 9-2.07 0-4.02-.68-5.73-1.89l-2.6 2.6c-.38.38-1 .38-1.38 0-.38-.38-.38-1 0-1.38l2.48-2.48C2.19 15.33.9 13.33 0 11c2.07-5.35 6.22-9 11-9 2.07 0 4.02.68 5.73 1.89l2.6-2.6a.96.96 0 0 1 1.38 0z',
  Filter:
    'M19 0c.55 0 1 .45 1 1v5h-2V1c0-.55.45-1 1-1zm2 8c.55 0 1 .45 1 1s-.45 1-1 1h-1v11c0 .55-.45 1-1 1s-1-.45-1-1V10h-1c-.55 0-1-.45-1-1s.45-1 1-1h4zM3 0c.55 0 1 .45 1 1v2H2V1c0-.55.45-1 1-1zm2 5c.55 0 1 .45 1 1s-.45 1-1 1H4v14c0 .55-.45 1-1 1s-1-.45-1-1V7H1c-.55 0-1-.45-1-1s.45-1 1-1h4zm6-5c.55 0 1 .45 1 1v10h-2V1c0-.55.45-1 1-1zm2 13c.55 0 1 .45 1 1s-.45 1-1 1h-1v6c0 .55-.45 1-1 1s-1-.45-1-1v-6H9c-.55 0-1-.45-1-1s.45-1 1-1h4z',
  CircleExclamation: GeneralWarning,
  GeneralWarning,
  CircleQuestion:
    'M11 4c2.21 0 4 1.79 4 4 0 1.81-1.2 3.33-2.84 3.83l-.16.04V14c0 .51-.39.94-.88.99L11 15c-.55 0-1-.45-1-1v-3c0-.55.45-1 1-1 1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 .55-.45 1-1 1s-1-.45-1-1c0-2.21 1.79-4 4-4zm0 12c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0-14a9 9 0 1 0 .001 18.001A9 9 0 0 0 11 2zm0-2c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0z',
  SquareExclamation:
    'M11 6.01c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM20 20H2V2h18v18zM2 0C.9 0 0 .9 0 2v18c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2H2zm8 11v4c0 .55.45 1 1 1s1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1z',
  ExclamationMark:
    'M11 14.25a1 1 0 0 1-1-1v-10a1 1 0 0 1 2 0v10a1 1 0 0 1-1 1zm0 5.5a1.5 1.5 0 1 0-.001-3.001A1.5 1.5 0 0 0 11 19.75z',
  ExclamationMarkRounded:
    'M11 12.105c-.828 0-1.5-.605-1.5-1.351V5.351C9.5 4.605 10.172 4 11 4s1.5.605 1.5 1.351v5.404c0 .745-.672 1.35-1.5 1.35zM11 18c-.828 0-1.5-.66-1.5-1.474s.672-1.474 1.5-1.474 1.5.66 1.5 1.474S11.828 18 11 18z',
  CircleI:
    'M11 2a9 9 0 1 0 .001 18.001A9 9 0 0 0 11 2zm0-2c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0zm0 6.01c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zM11 10c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1s-1-.45-1-1v-4c0-.55.45-1 1-1z',
  Globe:
    'm14.23 2.6.05.1c.48.92.88 2.04 1.17 3.3h3.03a9.016 9.016 0 0 0-4.25-3.4zM11 2c-.39 0-1.07.64-1.71 2.05-.27.58-.5 1.23-.68 1.95h4.79c-.19-.71-.42-1.37-.68-1.95-.61-1.33-1.25-1.98-1.65-2.04L11 2zm-3.23.59-.04.02C6 3.29 4.53 4.48 3.52 6h3.03c.3-1.31.72-2.47 1.22-3.41zM19.49 8h-3.68l.07.58c.08.78.12 1.59.12 2.42 0 .68-.03 1.35-.08 2h3.86c.15-.64.22-1.31.22-2 0-1.05-.18-2.06-.51-3zM6.19 8H2.51C2.18 8.94 2 9.95 2 11c0 .69.08 1.36.22 2h3.86a24.024 24.024 0 0 1 .11-5zm7.6 0H8.21l-.08.58C8.05 9.36 8 10.17 8 11c0 .68.03 1.35.09 2h5.82l.04-.49c.03-.49.05-1 .05-1.51 0-1.04-.07-2.05-.21-3zm-7.45 7h-3.4c1 2.01 2.73 3.59 4.84 4.4-.64-1.17-1.13-2.68-1.44-4.4zm9.32 0c-.31 1.72-.8 3.23-1.43 4.4a9.031 9.031 0 0 0 4.84-4.4h-3.41zm-2.04 0H8.38c.22 1.11.53 2.11.91 2.95C9.93 19.36 10.61 20 11 20s1.07-.64 1.71-2.05c.39-.84.7-1.84.91-2.95zM11 0c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0z',
  List: 'M5.5 17c0 .55.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1h-9c-.55 0-1 .45-1 1zm0-6c0 .55.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1h-9c-.55 0-1 .45-1 1zm0-6c0 .55.45 1 1 1h9c.55 0 1-.45 1-1s-.45-1-1-1h-9c-.55 0-1 .45-1 1z',
  Logout:
    'M19.71 10.29c.39.39.39 1.02 0 1.41l-6 6a.996.996 0 1 1-1.41-1.41L17.59 11 12.3 5.71a.995.995 0 0 1-.08-1.32l.08-.09a.996.996 0 0 1 1.41 0l6 5.99zM3 0c-.55 0-1 .45-1 1v20c0 .55.45 1 1 1h4c.55 0 1-.45 1-1l-.01-.12A.996.996 0 0 0 7 20H4V2h3c.55 0 1-.45 1-1s-.45-1-1-1H3z',
  Shrink:
    'M10 13v6c0 .55-.45 1-1 1s-1-.45-1-1v-3.59L3.71 19.7a.996.996 0 1 1-1.41-1.41L6.59 14H3c-.51 0-.94-.39-.99-.88L2 13c0-.55.45-1 1-1h6c.55 0 1 .45 1 1zm9-5h-3.59l4.29-4.29a.996.996 0 1 0-1.41-1.41L14 6.59V3c0-.55-.45-1-1-1s-1 .45-1 1v6c0 .55.45 1 1 1h6c.55 0 1-.45 1-1l-.01-.12A.996.996 0 0 0 19 8z',
  Minus: 'M0 11c0 .55.45 1 1 1h20c.55 0 1-.45 1-1s-.45-1-1-1H1c-.55 0-1 .45-1 1z',
  More: 'M11 17.98c.55 0 1 .45 1 1.01S11.55 20 11 20s-1-.45-1-1.01.45-1.01 1-1.01zm0-2.01c-1.66 0-3 1.35-3 3.02S9.34 22 11 22s3-1.35 3-3.02-1.34-3.01-3-3.01zm0-5.98c.55 0 1 .45 1 1.01s-.45 1.01-1 1.01-1-.45-1-1.01.45-1.01 1-1.01zm0-2.01c-1.66 0-3 1.35-3 3.02s1.34 3.02 3 3.02 3-1.35 3-3.02-1.34-3.02-3-3.02zm0-5.97c.55 0 1 .45 1 1.01s-.45 1.01-1 1.01-1-.45-1-1.01.45-1.01 1-1.01zM11 0C9.34 0 8 1.35 8 3.02s1.34 3.02 3 3.02 3-1.35 3-3.02S12.66 0 11 0z',
  Bell: 'M19.39 7.85C19.1 3.43 15.43 0 11 0S2.9 3.43 2.61 7.85L2 16.87C1.93 18.02 2.84 19 4 19h3.14c.45 1.72 2 3 3.86 3s3.41-1.28 3.86-3H18c1.16 0 2.07-.98 2-2.13l-.61-9.02zM11 20c-.74 0-1.38-.4-1.72-1h3.45c-.35.6-.99 1-1.73 1zm-7-3 .6-9.01A6.417 6.417 0 0 1 11 2c3.38 0 6.17 2.62 6.4 5.99L18 17H4z',
  Plus: 'M11 0c.55 0 1 .45 1 1v9h9c.51 0 .94.39.99.88L22 11c0 .55-.45 1-1 1h-9v9c0 .51-.39.94-.88.99L11 22c-.55 0-1-.45-1-1v-9H1c-.51 0-.94-.39-.99-.88L0 11c0-.55.45-1 1-1h9V1c0-.51.39-.94.88-.99L11 0z',
  User: 'M11 13c4.42 0 8 3.58 8 8 0 .55-.45 1-1 1s-1-.45-1-1c0-3.31-2.69-6-6-6s-6 2.69-6 6c0 .55-.45 1-1 1s-1-.45-1-1c0-4.42 3.58-8 8-8zm0-11c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0-2C7.69 0 5 2.69 5 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6z',
  ArrowRotateRight:
    'M12 2a9 9 0 1 1-9 9c-.21.28-.37.5-.5.67l-.1.13c-.26.35-.38.51-.6.8-.33.44-.96.53-1.4.2-.44-.33-.53-.96-.2-1.4.18-.24.29-.39.48-.64l.22-.29c.12-.16.26-.35.45-.59l.2-.26c.14-.19.3-.4.49-.65l.3-.4c.16-.21.33-.44.52-.69l.34-.48c.4-.53 1.2-.53 1.6 0l3 4c.33.44.24 1.07-.2 1.4-.44.33-1.07.24-1.4-.2L5 11c0 3.87 3.13 7 7 7s7-3.13 7-7-3.13-7-7-7c-.55 0-1-.45-1-1s.45-1 1-1z',
  ArrowRepeat:
    'M0 8.87C0 5.64 2.83 3 6.33 3h9.37c3.4 0 6.2 2.51 6.3 5.63v.25c0 3.25-2.87 5.9-6.4 5.9h-1.62l1.83 1.69c.35.33.38.83.09 1.18l-.09.09-.03.02-.02.02c-.13.12-.29.19-.45.21l-.13.01h-.07c-.26 0-.51-.09-.69-.26l-3.48-3.21a.942.942 0 0 1-.27-.63c0-.24.09-.47.28-.64l3.49-3.22c.39-.36 1-.36 1.38 0 .39.36.39.92 0 1.27L14 13.01h1.62c2.38 0 4.34-1.73 4.46-3.9l.01-.21c0-2.27-2-4.11-4.46-4.11H6.4c-2.46 0-4.46 1.84-4.46 4.11 0 2.27 2 4.11 4.46 4.11h1.1c.53 0 .97.41.97.89s-.45.9-.97.9H6.4C2.95 14.8.13 12.27 0 9.12v-.25z',
  MagnifyingGlass:
    'M8.04 2.92c-2.81 0-5.08 2.23-5.08 4.99s2.28 4.99 5.08 4.99 5.08-2.23 5.08-4.99-2.28-4.99-5.08-4.99zm5.42 9.39c-.39.47-.85.88-1.36 1.24l5.47 5.37c.36.35.95.35 1.31 0 .36-.35.36-.93 0-1.28l-5.42-5.33zM8.04 1c3.89 0 7.04 3.09 7.04 6.91 0 1-.22 1.96-.61 2.82l5.73 5.62c1.08 1.06 1.08 2.79 0 3.85s-2.84 1.06-3.92 0l-5.88-5.78c-.73.25-1.52.39-2.35.39-3.9.01-7.05-3.09-7.05-6.9C1 4.09 4.15 1 8.04 1z',
  Gear: 'M11 9c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-2c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm2.12-5H8.88l-.25 1.98c-.32.11-.63.23-.93.38L6.14 3.14l-3 3L4.36 7.7c-.15.3-.27.61-.38.93L2 8.88v4.24l1.98.25c.11.32.23.63.38.93l-1.22 1.56 2.99 2.99 1.56-1.22c.3.15.61.27.93.38L8.88 20h4.24l.25-1.98c.32-.11.63-.23.93-.38l1.56 1.22 2.99-2.99-1.22-1.56c.15-.3.27-.61.38-.93l1.99-.26V8.88l-1.98-.25c-.11-.32-.23-.63-.38-.93l1.22-1.56-2.99-2.99-1.57 1.21c-.3-.15-.61-.27-.93-.38L13.12 2zm0-2c.85 0 1.59.53 1.87 1.3l.01.04.08-.04c.68-.29 1.48-.18 2.06.31l.13.12 2.99 2.99c.59.59.74 1.46.43 2.19l-.03.09.04.01c.72.27 1.23.93 1.29 1.72l.01.15v4.24c0 .85-.53 1.59-1.3 1.87l-.04.01.04.08c.29.68.18 1.48-.31 2.06l-.12.13-2.99 2.99c-.59.59-1.46.74-2.19.43l-.09-.03-.01.04c-.27.72-.93 1.23-1.72 1.29l-.15.01H8.88c-.85 0-1.59-.53-1.87-1.3L7 20.66l-.08.04c-.68.29-1.48.18-2.06-.31l-.13-.12-2.99-2.99c-.59-.59-.74-1.46-.43-2.19l.03-.09-.04-.01c-.72-.27-1.23-.93-1.29-1.72L0 13.12V8.88c0-.85.53-1.58 1.3-1.87L1.34 7l-.04-.08c-.29-.69-.18-1.49.31-2.07l.12-.13 2.99-2.99c.59-.59 1.46-.74 2.2-.43l.08.04.01-.04C7.28.58 7.94.07 8.73.01L8.88 0h4.24z',
  ShareNodes:
    'M15.5 2c-.83 0-1.5.67-1.5 1.5S14.67 5 15.5 5 17 4.33 17 3.5 16.33 2 15.5 2zM5.1 9.5c-.85 0-1.54.67-1.54 1.5s.69 1.5 1.54 1.5 1.54-.67 1.54-1.5-.69-1.5-1.54-1.5zM15.5 17c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5 1.5-.67 1.5-1.5-.67-1.5-1.5-1.5zm0-17C17.43 0 19 1.57 19 3.5S17.43 7 15.5 7c-.73 0-1.41-.22-1.97-.61l-5.08 4.02c.03.19.05.39.05.59 0 .22-.02.43-.06.64l5.1 3.97c.56-.38 1.23-.6 1.96-.6 1.93 0 3.5 1.57 3.5 3.5S17.43 22 15.5 22a3.504 3.504 0 0 1-3.24-4.83l-4.77-3.71A3.48 3.48 0 0 1 5 14.5c-1.93 0-3.5-1.57-3.5-3.5S3.07 7.5 5 7.5c.99 0 1.88.41 2.52 1.07l4.74-3.75c-.17-.41-.26-.85-.26-1.32C12 1.57 13.57 0 15.5 0z',
  SortArrowDown:
    'm9.467 15.28-5-6C3.382 7.978 4.308 6 6.004 6h10c1.695 0 2.622 1.978 1.536 3.28l-5 6a2 2 0 0 1-3.073 0ZM11.004 14l5-6h-10l5 6Z',
  SortArrowFilledDown:
    'm9.467 15.28-5-6C3.382 7.978 4.308 6 6.004 6h10c1.695 0 2.622 1.978 1.536 3.28l-5 6a2 2 0 0 1-3.073 0Z',
  SortArrowUp:
    'm12.54 6.72 5 6c1.086 1.302.16 3.28-1.536 3.28h-10c-1.696 0-2.622-1.978-1.537-3.28l5-6a2 2 0 0 1 3.073 0ZM11.004 8l-5 6h10l-5-6Z',
  SortArrowFilledUp:
    'm12.54 6.72 5 6c1.086 1.302.16 3.28-1.536 3.28h-10c-1.696 0-2.622-1.978-1.537-3.28l5-6a2 2 0 0 1 3.073 0Z',
  Star: 'm11 2 2.75 5.98 6.25.9-4.56 4.59L16.56 20 11 16.86 5.44 20l1.12-6.53L2 8.88l6.25-.9L11 2zM6.92 6.09l-5.2.74-.15.03C.03 7.2-.55 9.23.62 10.39l3.79 3.78-.93 5.38-.03.15c-.17 1.63 1.5 2.84 2.93 2.04L11 19.16l4.62 2.58.13.07c1.44.67 3.06-.61 2.77-2.27l-.93-5.38 3.79-3.78.11-.12c1.05-1.22.38-3.21-1.22-3.44l-5.2-.74-2.28-4.92a1.962 1.962 0 0 0-3.59 0L6.92 6.09z',
  Clock:
    'M11.03 4.83c.55 0 1 .45 1 1v4.67l2.38 1.37c.48.28.64.89.37 1.37l-.03.05c-.28.48-.89.64-1.37.37l-2.85-1.64a.972.972 0 0 1-.43-.51.95.95 0 0 1-.12-.48v-5.2c0-.55.45-1 1-1h.05zM11 2.06c-4.94 0-8.94 4-8.94 8.94s4 8.94 8.94 8.94 8.94-4 8.94-8.94-4-8.94-8.94-8.94zM11 0c6.08 0 11 4.92 11 11s-4.92 11-11 11S0 17.08 0 11 4.92 0 11 0z',
  TrashBin:
    'M18.11 0c.49 0 .89.45.89 1s-.4 1-.89 1H3.89C3.4 2 3 1.55 3 1s.4-1 .89-1h14.22zM18 6H4l2 14h10l2-14zm3-2c.55 0 1 .45 1 1s-.45 1-1 1h-1c0 .09-.01.19-.02.28l-2 14C17.84 21.27 17 22 16 22H6c-1 0-1.84-.73-1.98-1.72l-2-14C2.01 6.19 2 6.09 2 6H1c-.55 0-1-.45-1-1s.45-1 1-1h20z',
  Upload:
    'M17.71 10.29c.39.39.39 1.02 0 1.41l-.09.08c-.39.31-.96.28-1.32-.08L11 6.41 5.71 11.7a.996.996 0 1 1-1.41-1.41l6-6a.996.996 0 0 1 1.41 0l6 6zM2.87 16.01C1.67 16.14 1.71 18 3 18h16c1.33 0 1.33-2 0-2H3l-.13.01z',
  Notes:
    'M17.75 0C18.993 0 20 .985 20 2.2v17.6c0 1.215-1.007 2.2-2.25 2.2H4.25C3.007 22 2 21.015 2 19.8V2.2C2 .985 3.007 0 4.25 0h13.5ZM18 2H4v18h14V2Zm-3.25 14c.69 0 1.25.448 1.25 1s-.56 1-1.25 1h-7.5C6.56 18 6 17.552 6 17s.56-1 1.25-1h7.5Zm0-6c.69 0 1.25.448 1.25 1s-.56 1-1.25 1h-7.5C6.56 12 6 11.552 6 11s.56-1 1.25-1h7.5Zm0-6c.69 0 1.25.448 1.25 1s-.56 1-1.25 1h-7.5C6.56 6 6 5.552 6 5s.56-1 1.25-1h7.5Z',
  WindDirection:
    'm11.807 3.55 4.168 14.546a.647.647 0 0 1-.076.524c-.22.353-.724.483-1.128.291l-3.374-1.605a.937.937 0 0 0-.794 0l-3.374 1.605a.938.938 0 0 1-.6.067c-.445-.098-.715-.492-.604-.882L10.194 3.55c.074-.26.307-.463.604-.528.445-.098.897.139 1.009.528ZM11 8.743l-2.028 7.076.592-.281a3.384 3.384 0 0 1 2.677-.085l.195.085.59.281L11 8.743Z'
}

export default uiIcons
