import { type Sign } from '@/lib/anoveda/apis/traffic-signs/types'
import { type HazardEventCause } from '@/lib/mercedes-benz-data/apis/hazard-warnings/types'

import illustrationIcons from './groups/illustrationIcons'
import productIcons from './groups/productIcons'
import specialIcons from './groups/specialIcons'
import trafficSignIcons from './groups/trafficSignIcons'
import uiIcons from './groups/uiIcons'

export type IconName = keyof typeof groupIconPaths | keyof typeof aliases

type Aliases =
  | 'Cyclists'
  | 'CYCLISTS'
  | 'Vehicles'
  | 'VEHICLES'
  | 'hazard-warnings'
  | 'Pedestrians'
  | 'PEDESTRIANS'
  | 'road-safety-hotspots'
  | 'slippery-road'
  | 'traffic-signs'
  | 'micro-weather'
  | 'surface-events'
  | 'rainIntensityNONE'
  | 'rainIntensityLOW'
  | 'rainIntensityMEDIUM'
  | 'rainIntensityHIGH'
  | 'rainIntensityUNRECOGNIZED'
  | 'waviness'
  | 'parking-analytics-basic'
  | 'parking-analytics-advanced'
  | 'publicTransport'
  | 'school'
  | 'publicServices'
  | 'clinic'
  | 'shop'
  | 'restaurant'

type IconPathType = Record<IconName | Aliases | HazardEventCause | Sign, string>

const aliases: Record<Aliases | HazardEventCause, string> = {
  BDV: specialIcons.BrokenDownVehicle,
  CW: specialIcons.CrossWind,
  EB: specialIcons.EmergencyBrake,
  FOG: specialIcons.Fog,
  GW: uiIcons.GeneralWarning,
  HL: specialIcons.HazardLight,
  HR: specialIcons.HeavyRain,
  PH: productIcons.Pothole,
  SR: productIcons.SlipperyRoad,
  VA: specialIcons.VehicleAccident,
  BUM: specialIcons.Bump,

  Cyclists: specialIcons.Bike,
  CYCLISTS: specialIcons.Bike,
  Vehicles: specialIcons.Car,
  VEHICLES: specialIcons.Car,
  Pedestrians: specialIcons.Pedestrian,
  PEDESTRIANS: specialIcons.Pedestrian,

  'hazard-warnings': productIcons.HazardWarnings,
  'road-safety-hotspots': productIcons.RoadSafety,
  'slippery-road': productIcons.SlipperyRoad,
  'traffic-signs': productIcons.TrafficSigns,
  'micro-weather': productIcons.Weather,
  'surface-events': productIcons.SurfaceEvents,
  waviness: productIcons.Waviness,
  'parking-analytics-basic': productIcons.ParkingAnalytics,
  'parking-analytics-advanced': productIcons.ParkingAnalytics,

  rainIntensityNONE: specialIcons.RainIntensityNone,
  rainIntensityLOW: specialIcons.RainIntensityLow,
  rainIntensityMEDIUM: specialIcons.RainIntensityMedium,
  rainIntensityHIGH: specialIcons.RainIntensityHigh,
  rainIntensityUNRECOGNIZED: specialIcons.BrokenDownVehicle,

  publicTransport: specialIcons.BusStop,
  school: specialIcons.School,
  publicServices: specialIcons.Public,
  clinic: specialIcons.Medical,
  shop: specialIcons.Shopping,
  restaurant: specialIcons.Restaurants
}

const groupIconPaths = {
  ...uiIcons,
  ...specialIcons,
  ...productIcons,
  ...illustrationIcons,
  ...trafficSignIcons
}

export const iconPaths: IconPathType = { ...groupIconPaths, ...aliases }

export const iconsWithoutAlias = groupIconPaths
